module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-layout-virtual-e84ef84ce3/3/.yarn/berry/cache/gatsby-plugin-layout-npm-4.13.1-cd4d67cded-10c0.zip/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-next-seo-virtual-67c88efab4/3/.yarn/berry/cache/gatsby-plugin-next-seo-npm-1.11.0-9def787574-10c0.zip/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"","titleTemplate":"%s | Jewelry 3D by Highlights jewelry","language":"ru","openGraph":{"type":"website","locale":"ru_RU","url":"https://highlights.mustbefamily.com/","site_name":"Jewelry 3D by Highlights jewelry","images":[{"url":"https://highlights.mustbefamily.com/images/social_all.jpg","width":720,"height":720,"alt":"Jewelry 3D by Highlights jewelry"}]},"twitter":{"handle":"@mustbefamily","site":"@mustbefamily","cardType":"summary_large_image"}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-yandex-metrika-virtual-a97a71540b/3/.yarn/berry/cache/gatsby-plugin-yandex-metrika-npm-2.5.0-e6ddce76a6-10c0.zip/node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":88079194,"afterBody":true,"defer":true},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-intl-v4-virtual-7ac5189422/3/.yarn/berry/cache/gatsby-plugin-intl-v4-npm-0.3.6-a9b0fcf31a-10c0.zip/node_modules/gatsby-plugin-intl-v4/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/ivan/work/metajewelry/src/intl","languages":["ru"],"defaultLanguage":"ru","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-547412a70a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
